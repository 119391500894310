import {
  FETCH_TEAM,
  SET_SELECTED_TAB,
  TOGGLE_SELECTED_PLAYER,
  RESET_SELECTED_PLAYERS,
  SET_SELECTED_INDEX,
  SET_BUDGET,
  SET_SELECTED_VIEW,
  TOGGLE_PLAYER_LIST,
  TOGGLE_TURBO_DRIVER_POPUP,
  TOGGLE_TEAM_NAME_POPUP,
  SET_TURBO_DRIVER,
  SET_TEMP_TURBO_DRIVER,
  SET_TEAM_NAME,
  SAVE_TEAM,
  SAVE_TEAM_RESET,
  TOGGLE_PROFILE_POPUP,
  SET_PROFILE_DATA,
  TRANSFER_TEAM,
  UPDATE_CAPTAIN,
  SET_SELECTED_TEAM,
  TOGGLE_TRANSFER_POPUP,
  TOGGLE_CHIPS_POPUP,
  SET_SELECTED_BOOSTER,
  SET_TEMP_BOOSTER,
  TOGGLE_FILTER_POPUP,
  SET_FILTERS,
  APPLY_FILTERS,
  RESET_FILTERS,
  FETCH_BOOSTERS,
  TOGGLE_ALERT_POPUP,
  TOGGLE_CIRCUIT_POPUP,
  TOGGLE_CONFIRM_CHIPS_POPUP,
  TOGGLE_PLAYER_POPUP,
  SET_REPLACED_PLAYER,
  TOGGLE_EXTRA_TURBO_DRIVER_POPUP,
  SET_EXTRA_TURBO_DRIVER,
  SET_TEMP_EXTRA_TURBO_DRIVER,
  UPDATE_TEAM_NAME,
  RESET_UPDATE_TEAM_NAME,
  TRANSFER_TEAM_RESET,
  GET_USER_GAME_DAYS,
  FETCH_DETAILED_TEAM,
  RESET_UPDATE_CAPTAIN,
  TOGGLE_RACE_WEEK_TAB,
  RESET_GO_BACK,
  FETCH_OPP_DETAILED_TEAM,
  DISCARD_TRANSFERS,
  FETCH_OPP_USER_GAME_DAYS,
  RESET_GAMEPLAY_PERSISTER,
  GET_USER_GAME_DAYS_V1,
  FETCH_H2H_OPPONENT_TEAM,
  FETCH_H2H_OPPONENT_USER_GAME_DAYS,
  RESET_H2H_OPPONENT,
  RESET_OPP_TEAM,
  FETCH_TEAM_FOR_RACEWEEK,
  FETCH_RATING_STATISTICS,
  FETCH_SORTING_STATISTICS
} from "../../constants/gameplay";
import {
  APPLY_AUTO_PILOT,
  APPLY_EXTRA_DRS,
  APPLY_FINAL_FIX,
  APPLY_LIMITLESS,
  APPLY_NON_NEGATIVE,
  CHANGE_EXTRA_DRS,
  RESET_AUTO_PILOT,
  RESET_CHANGE_EXTRA_DRS,
  RESET_EXTRA_DRS,
  RESET_FINAL_FIX,
  RESET_LIMITLESS,
  RESET_NON_NEGATIVE,
} from "../../constants/booster";
import { gameplay, feeds, booster } from "../../apis/services";

export const fetchTeam = (params) => {
  const { payload, drivers, livePointsBuster = false } = params;
  return {
    type: FETCH_TEAM,
    payload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const data = await gameplay.getTeam(payload, livePointsBuster);
          resolve({ ...data, drivers });
        } catch (error) {
          reject(error);
        }
      });
    },
  };
};

export const fetchTeamForRaceweek = (params) => {
  const { payload, drivers } = params;
  return {
    type: FETCH_TEAM_FOR_RACEWEEK,
    payload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const data = await gameplay.getTeam(payload);
          resolve({ ...data, drivers });
        } catch (error) {
          reject(error);
        }
      });
    },
  };
};

export const setSelectedTab = (params) => {
  return {
    type: SET_SELECTED_TAB,
    payload: params,
  };
};

export const togglePlayer = (params) => {
  return {
    type: TOGGLE_SELECTED_PLAYER,
    payload: params,
  };
};

export const resetPlayers = (params) => {
  return {
    type: RESET_SELECTED_PLAYERS,
    payload: params,
  };
};

export const setSelectedIndex = (params) => {
  return {
    type: SET_SELECTED_INDEX,
    payload: params,
  };
};

export const setBudget = (params) => {
  return {
    type: SET_BUDGET,
    payload: params,
  };
};

export const setSelectedView = () => {
  return {
    type: SET_SELECTED_VIEW,
  };
};

export const togglePlayerList = () => {
  return {
    type: TOGGLE_PLAYER_LIST,
  };
};

export const toggleTurboDriverPopup = (params) => {
  return {
    type: TOGGLE_TURBO_DRIVER_POPUP,
    payload: params,
  };
};

export const toggleTeamNamePopup = (params) => {
  return {
    type: TOGGLE_TEAM_NAME_POPUP,
    payload: params,
  };
};

export const toggleProfilePopup = (params) => {
  return {
    type: TOGGLE_PROFILE_POPUP,
    payload: params,
  };
};

export const toggleChipsPopup = (params) => {
  return {
    type: TOGGLE_CHIPS_POPUP,
    payload: params,
  };
};

export const toggleTransferPopup = (params) => {
  return {
    type: TOGGLE_TRANSFER_POPUP,
    payload: params,
  };
};

export const toggleFilterPopup = (params) => {
  return {
    type: TOGGLE_FILTER_POPUP,
    payload: params,
  };
};

export const setTurboDriver = (params) => {
  return {
    type: SET_TURBO_DRIVER,
    payload: params,
  };
};

export const setTempTurboDriver = (params) => {
  return {
    type: SET_TEMP_TURBO_DRIVER,
    payload: params,
  };
};

export const setTeamName = (params) => {
  return {
    type: SET_TEAM_NAME,
    payload: params,
  };
};

export const setProfileData = (params) => {
  return {
    type: SET_PROFILE_DATA,
    payload: params,
  };
};

export const saveTeam = (params) => {
  return {
    type: SAVE_TEAM,
    payload: gameplay.createTeam(params),
  };
};

export const resetSaveTeamStatus = () => {
  return {
    type: SAVE_TEAM_RESET,
  };
};

export const transferTeam = (params) => {
  return {
    type: TRANSFER_TEAM,
    payload: gameplay.transferTeam(params),
  };
};

export const resetTransferTeam = () => {
  return {
    type: TRANSFER_TEAM_RESET,
  };
};

export const updateCaptain = (params) => {
  return {
    type: UPDATE_CAPTAIN,
    payload: gameplay.updateCaptain(params),
  };
};

export const resetUpdateCaptain = () => {
  return {
    type: RESET_UPDATE_CAPTAIN,
  };
};

export const setSelectedTeam = (params) => {
  return {
    type: SET_SELECTED_TEAM,
    payload: params,
  };
};

export const setSelectedBooster = (params) => {
  return {
    type: SET_SELECTED_BOOSTER,
    payload: params,
  };
};

export const setTempBooster = (params) => {
  return {
    type: SET_TEMP_BOOSTER,
    payload: params,
  };
};

export const setFilters = (params) => {
  return {
    type: SET_FILTERS,
    payload: params,
  };
};

export const resetGoBack = (params) => {
  return {
    type: RESET_GO_BACK,
    payload: params,
  };
};

export const resetFilters = (params) => {
  return {
    type: RESET_FILTERS,
    payload: params,
  };
};

export const applyFilters = (params) => {
  return {
    type: APPLY_FILTERS,
    payload: params,
  };
};

export const fetchBoosters = () => {
  return {
    type: FETCH_BOOSTERS,
    payload: feeds.getBoostersList(),
  };
};

export const toggleAlertPopup = () => {
  return {
    type: TOGGLE_ALERT_POPUP,
  };
};

export const toggleCircuitPopup = () => {
  return {
    type: TOGGLE_CIRCUIT_POPUP,
  };
};

export const toggleConfirmChipsPopup = () => {
  return {
    type: TOGGLE_CONFIRM_CHIPS_POPUP,
  };
};

export const togglePlayerPopup = (params) => {
  return {
    type: TOGGLE_PLAYER_POPUP,
    payload: params,
  };
};

export const setReplacedPlayer = (params) => {
  return {
    type: SET_REPLACED_PLAYER,
    payload: params,
  };
};

export const toggleExtraTurboDriverPopup = () => {
  return {
    type: TOGGLE_EXTRA_TURBO_DRIVER_POPUP,
  };
};

export const setExtraTurboDriver = (params) => {
  return {
    type: SET_EXTRA_TURBO_DRIVER,
    payload: params,
  };
};

export const setTempExtraTurboDriver = (params) => {
  return {
    type: SET_TEMP_EXTRA_TURBO_DRIVER,
    payload: params,
  };
};

export const updateTeamName = (params) => {
  return {
    type: UPDATE_TEAM_NAME,
    payload: gameplay.updateTeamName(params),
  };
};

export const resetUpdateTeamName = () => {
  return {
    type: RESET_UPDATE_TEAM_NAME,
  };
};

export const applyAutoPilot = (params) => {
  return {
    type: APPLY_AUTO_PILOT,
    payload: booster.applyAutoPilot(params),
  };
};

export const resetAutoPilot = () => {
  return {
    type: RESET_AUTO_PILOT,
  };
};

export const applyExtraDRS = (params) => {
  return {
    type: APPLY_EXTRA_DRS,
    payload: booster.applyExtraDRS(params),
  };
};

export const resetExtraDRS = () => {
  return {
    type: RESET_EXTRA_DRS,
  };
};

export const changeExtraDRS = (params) => {
  return {
    type: CHANGE_EXTRA_DRS,
    payload: booster.changeExtraDRS(params),
  };
};

export const resetChangeExtraDRS = () => {
  return {
    type: RESET_CHANGE_EXTRA_DRS,
  };
};

export const applyFinalFix = (params) => {
  return {
    type: APPLY_FINAL_FIX,
    payload: booster.applyFinalFix(params),
  };
};

export const resetFinalFix = () => {
  return {
    type: RESET_FINAL_FIX,
  };
};

export const applyLimitless = (params) => {
  return {
    type: APPLY_LIMITLESS,
    payload: booster.applyLimitless(params),
  };
};

export const resetLimitless = () => {
  return {
    type: RESET_LIMITLESS,
  };
};

export const applyNoNegative = (params) => {
  return {
    type: APPLY_NON_NEGATIVE,
    payload: booster.applyNoNegative(params),
  };
};

export const resetNoNegative = () => {
  return {
    type: RESET_NON_NEGATIVE,
  };
};

export const getUserGameDays = (params) => {
  return {
    type: GET_USER_GAME_DAYS,
    payload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const data = await gameplay.getUserGameDays(params);
          resolve({ ...data, teamNo: params?.teamNo });
        } catch (error) {
          reject(error);
        }
      });
    },
  };
};

export const getUserGameDaysV1 = (params) => {
  return {
    type: GET_USER_GAME_DAYS_V1,
    payload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const data = await gameplay.getUserGameDaysV1(params);

          const result = {};
          data?.data.forEach((item) => (result[item?.teamno] = item));

          resolve({
            data: { ...result },
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  };
};
export const fetchDetailedTeam = (params) => {
  const { payload, drivers, selectedTeam, livePointsBuster } = params;
  return {
    type: FETCH_DETAILED_TEAM,
    payload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const data = await gameplay.getTeam(payload, livePointsBuster);
          resolve({ ...data, drivers, selectedTeam });
        } catch (error) {
          reject(error);
        }
      });
    },
  };
};

export const fetchOppDetailedTeam = (params) => {
  const { payload, drivers, selectedTeam } = params;
  return {
    type: FETCH_OPP_DETAILED_TEAM,
    payload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const data = await gameplay.getOppTeam(payload);
          resolve({
            ...data,
            drivers,
            selectedTeam,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  };
};

export const resetOppDetailedTeam = (params) => {
  return {
    type: RESET_OPP_TEAM,
  };
};

export const toggleRaceWeekTab = (params) => {
  return {
    type: TOGGLE_RACE_WEEK_TAB,
    payload: params,
  };
};

export const discardTransfers = () => {
  return {
    type: DISCARD_TRANSFERS,
  };
};

export const fetchOppUserGameDays = (params) => {
  return {
    type: FETCH_OPP_USER_GAME_DAYS,
    payload: gameplay.getOppUserGameDays(params),
  };
};

export const fetchH2HOppTeam = (params) => {
  const { payload, drivers, selectedTeam } = params;
  return {
    type: FETCH_H2H_OPPONENT_TEAM,
    payload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const data = await gameplay.getOppTeam(payload);
          resolve({
            ...data,
            drivers,
            selectedTeam,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  };
};

export const fetchH2HOppUserGameDays = (params) => {
  return {
    type: FETCH_H2H_OPPONENT_USER_GAME_DAYS,
    payload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const data = await gameplay.getOppUserGameDays(params);
          resolve({
            ...data,
            params,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  };
};

export const resetH2HOpponent = () => {
  return {
    type: RESET_H2H_OPPONENT,
  };
};

export const resetGameplayPersister = () => {
  return {
    type: RESET_GAMEPLAY_PERSISTER,
  };
};

export const getRatingStatistics = () => ({
  type: FETCH_RATING_STATISTICS,
  payload: feeds.getRatingStatistics()
});

export const getSortingStatistics = () => ({
  type: FETCH_SORTING_STATISTICS,
  payload: feeds.getSortingStatistics()
});